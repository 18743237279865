const localeCountryMap = {
  'nl-be': 'belgium_jobs',
  'fr-be': 'belgium_jobs',
  'en-be': 'belgium_jobs',
  'nl-nl': 'netherlands_jobs',
  'en-nl': 'netherlands_jobs',
}

const countryLocaleMap = {
  belgium: 'BE',
  germany: 'DE',
  netherlands: 'NL',
  france: 'FR',
}

const richTextStylesRegex = /(style=".+?")/gm

const jobsPerPage2Panes = 15

export {
  localeCountryMap,
  richTextStylesRegex,
  countryLocaleMap,
  jobsPerPage2Panes,
}
