import { useTranslations } from 'next-intl';
import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import GenericLink from '../../shared/Link'
import SocialMediaNav from './SocialMediaNav'
import Image from 'next/image'
import logo from '../../../public/sdworx_logo.svg'
import Link from 'next/link'
import { Navbar } from 'react-bootstrap'
import { DoormatMenuItem, SocialMediaMenuItem } from '../../../types/menus'

export interface DoormatProps {
  doormatMenuItems: DoormatMenuItem[] | null
  socialMenuItems: SocialMediaMenuItem[] | null
}

const Doormat: React.FC<DoormatProps> = ({
  doormatMenuItems,
  socialMenuItems,
}: DoormatProps) => {
  const topLevelDoormatMenuItems = doormatMenuItems?.filter(
    el => !el.entity_id.parent,
  )

  const t = useTranslations()
  return (
    <div className="c-footer__doormat position-relative">
      <Container fluid={true}>
        <Row>
          <Col>
            <Link href="/" passHref={true}>
              <Navbar.Brand className="ma c-footer__doormat__logo mb-3">
                <Image
                  width={160}
                  height={40}
                  alt="sdworx.jobs logo"
                  src={logo}
                  priority={true}
                />
              </Navbar.Brand>
            </Link>
          </Col>
        </Row>
        <Row className="c-footer__doormat--navWrapper flex-md-row flex-column">
          {topLevelDoormatMenuItems
            ?.sort((a, b) => a.entity_id.weight! - b.entity_id.weight!)
            .map((menuItem, index) => {
              const enabledChildren = doormatMenuItems?.filter(el =>
                el.entity_id.parent?.includes(menuItem.entity_id.id),
              )
              return (
                <Col key={menuItem.entity_id.id}>
                  {enabledChildren
                    ?.sort((a, b) => a.entity_id.weight! - b.entity_id.weight!)
                    .map(navHeaderItem => {
                      const enabledGrandChildren = doormatMenuItems?.filter(
                        el =>
                          el.entity_id.parent?.includes(
                            navHeaderItem.entity_id.id,
                          ),
                      )
                      return (
                        <div className="mb-3" key={navHeaderItem.entity_id.id}>
                          <GenericLink
                            extraClass="c-footer__doormat--navItem c-footer__doormat--navHeader"
                            link={navHeaderItem.entity_id.link}
                          >
                            {navHeaderItem.entity_id.title}
                          </GenericLink>
                          <div className="c-footer__doormat--navItem--childrenWrapper  flex-column flex-wrap ">
                            {enabledGrandChildren
                              ?.sort(
                                (a, b) =>
                                  a.entity_id.weight! - b.entity_id.weight!,
                              )
                              .map(navItem => (
                                <GenericLink
                                  extraClass="c-footer__doormat--navItem d-block p-0 mr-3"
                                  key={navItem.entity_id.id}
                                  link={navItem.entity_id.link}
                                >
                                  {navItem.entity_id.title}
                                </GenericLink>
                              ))}
                          </div>
                        </div>
                      )
                    })}
                  {index === topLevelDoormatMenuItems.length - 1 ? (
                    <div className="c-footer__doormat__navigation-approval mt-5">
                      <span
                        className="text-uppercase font-weight-bold"
                        dangerouslySetInnerHTML={{
                          __html: t.raw('navigation_approval_title') ?? '',
                        }}
                      />
                      <div
                        className="text-white mt-2"
                        dangerouslySetInnerHTML={{
                          __html: t.raw('navigation_approval_numbers') ?? '',
                        }}
                      ></div>
                    </div>
                  ) : null}
                </Col>
              )
            })}

          <Col>
            <SocialMediaNav socialMenuItems={socialMenuItems} />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Doormat
